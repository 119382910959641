import { logEvent, setUserId, setUserProperties, setCurrentScreen } from 'firebase/analytics';
import { UserResponse } from '@agroop/api/accounts/types';

import { useEffect } from 'react';
import { firebaseAnalytics } from './index';

const wildcardRE = /\*$/;

export function gaPageView(path: string) {
  return logEvent(firebaseAnalytics, 'page_view', {
    page_path: path.replace(wildcardRE, ''),
    // page_title: document.title,
    // page_location: location.href,
  });
}

export function gaSetUser(user?: UserResponse) {
  setUserProperties(firebaseAnalytics, { language: user?.language, appVersion: import.meta.env.VERSION, appName: BuildInfo.appName });
  setUserId(firebaseAnalytics, user?.userId ?? '');
}

export function gaReportError(where: string, error: Error, fatal: boolean) {
  logEvent(firebaseAnalytics, 'exception', {
    description: `'${where}': ${error.name} ${error.message}\n${error.stack}`,
    fatal,
  });
}

export function gaLogin(user: UserResponse, method: string) {
  gaSetUser(user);
  logEvent(firebaseAnalytics, 'login', { method });
}

export function gaSignUp(user: UserResponse, method: string) {
  gaSetUser(user);
  logEvent(firebaseAnalytics, 'sign_up', { method });
}

export function useSetScreenName(screenName: string, params: Record<string, any> = {}) {
  useEffect(() => {
    setCurrentScreen(firebaseAnalytics, screenName);
    logEvent(firebaseAnalytics, 'screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: `${screenName}Class`,
      ...params,
    });
  }, [screenName]);
}

export function buttonClick(method: string) {
  logEvent(firebaseAnalytics, 'button_click', { method });
}
