import React, { useState } from 'react';
import cn from 'clsx';
import { getBackgroundImageUrl } from '@agroop/bg-images';

import { matchSmallUI, useMediaQuery } from '../../utils';

import styles from './BgImage.module.scss';

interface BgImageProps {
  className?: string;
  full?: boolean;
}

export default function BgImage(props: React.PropsWithChildren<BgImageProps>) {
  const [bgImage] = useState(getBackgroundImageUrl);
  const [isSmallUI] = useMediaQuery(matchSmallUI);
  return (
    <React.Fragment>
      {!isSmallUI && (
        <div className={cn(styles.root, props.className, { [styles.full]: props.full })} style={{ backgroundImage: `URL(${bgImage})` }} />
      )}
      {props.children}
    </React.Fragment>
  );
}
