/**
 * Auto-generated, do not edit, it will be overwritten
 * https://github.com/porfirioribeiro/openapi2ts
 */
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { request, ResponsePromise } from '..';
import * as types from './types';
/** Get entities associated with the user and that this user can access */
export const getUserEntities = (): ResponsePromise<types.EntityWithAuthorities[], any> =>
  request({
    module: 'farmoo',
    method: 'GET',
    uri: '/entities',
  });

export const getUserEntitiesKey = 'getUserEntities';

export const useGetUserEntities = <TData = types.EntityWithAuthorities[]>(
  queryConfig?: Partial<UseQueryOptions<types.EntityWithAuthorities[], unknown, TData>>,
) => useQuery({ queryFn: async () => getUserEntities(), queryKey: [getUserEntitiesKey], ...queryConfig });

export const removeCurrentUserFromEntity = (): ResponsePromise<unknown, any> =>
  request({
    module: 'farmoo',
    method: 'DELETE',
    uri: '/entities',
  });

export interface CreateAnalogConsultantOptions {
  body: types.AnalogConsultantRequest;
}
/** Create a new entity of type CONSULTANT */
export const createAnalogConsultant = (
  options: CreateAnalogConsultantOptions,
): ResponsePromise<types.EntityWithAuthorities, CreateAnalogConsultantOptions> =>
  request({
    module: 'farmoo',
    method: 'POST',
    uri: '/entities/analogConsultant',
    body: options.body,
    options,
  });

export interface CreateAnalogFarmerOptions {
  body: types.AnalogFarmerRequest;
}
/** Create a new entity of type FARMER with a subscription of type ANALOG_FARMER */
export const createAnalogFarmer = (
  options: CreateAnalogFarmerOptions,
): ResponsePromise<types.EntityWithAuthorities, CreateAnalogFarmerOptions> =>
  request({
    module: 'farmoo',
    method: 'POST',
    uri: '/entities/analogFarmer',
    body: options.body,
    options,
  });
/** Get entities connected with the user */
export const getUserEntityConnections = (): ResponsePromise<types.UserEntityConnectionResponse[], any> =>
  request({
    module: 'farmoo',
    method: 'GET',
    uri: '/entities/connections',
  });

export const getUserEntityConnectionsKey = 'getUserEntityConnections';

export const useGetUserEntityConnections = <TData = types.UserEntityConnectionResponse[]>(
  queryConfig?: Partial<UseQueryOptions<types.UserEntityConnectionResponse[], unknown, TData>>,
) => useQuery({ queryFn: async () => getUserEntityConnections(), queryKey: [getUserEntityConnectionsKey], ...queryConfig });

export const getCurrentEntity = (): ResponsePromise<types.EntityResponse, any> =>
  request({
    module: 'farmoo',
    method: 'GET',
    uri: '/entities/current',
  });

export const getCurrentEntityKey = 'getCurrentEntity';

export const useGetCurrentEntity = <TData = types.EntityResponse>(
  queryConfig?: Partial<UseQueryOptions<types.EntityResponse, unknown, TData>>,
) => useQuery({ queryFn: async () => getCurrentEntity(), queryKey: [getCurrentEntityKey], ...queryConfig });

export interface UpdateCurrentEntityOptions {
  body: types.UpdateEntityRequest;
}

export const updateCurrentEntity = (
  options: UpdateCurrentEntityOptions,
): ResponsePromise<types.EntityResponse, UpdateCurrentEntityOptions> =>
  request({
    module: 'farmoo',
    method: 'PUT',
    uri: '/entities/current/update',
    body: options.body,
    options,
  });

export interface CreateDigitalFarmerTrialOptions {
  body: types.DigitalFarmerRequest;
}
/** Creates a new entity of type FARMER with a subscription to a trial */
export const createDigitalFarmerTrial = (
  options: CreateDigitalFarmerTrialOptions,
): ResponsePromise<types.EntityWithAuthorities, CreateDigitalFarmerTrialOptions> =>
  request({
    module: 'farmoo',
    method: 'POST',
    uri: '/entities/digitalFarmer',
    body: options.body,
    options,
  });

export interface GetEntitiesByOwnerEmailOptions {
  email: string;
  entityId: string;
}
/** Get all entities where user is the owner */
export const getEntitiesByOwnerEmail = (
  options: GetEntitiesByOwnerEmailOptions,
): ResponsePromise<types.EntityByUserResponse, GetEntitiesByOwnerEmailOptions> =>
  request({
    module: 'farmoo',
    method: 'GET',
    uri: '/entities/entityOwner',
    query: { email: options.email, entityId: options.entityId },
    options,
  });

export const getEntitiesByOwnerEmailKey = 'getEntitiesByOwnerEmail';

export const useGetEntitiesByOwnerEmail = <TData = types.EntityByUserResponse>(
  options: GetEntitiesByOwnerEmailOptions,
  queryConfig?: Partial<UseQueryOptions<types.EntityByUserResponse, unknown, TData>>,
) =>
  useQuery({
    queryFn: async () => getEntitiesByOwnerEmail(options),
    queryKey: [getEntitiesByOwnerEmailKey, options.email, options.entityId],
    ...queryConfig,
  });

export interface RemoveCurrentUserFromEntityByIdOptions {
  entityId: string;
}

export const removeCurrentUserFromEntityById = (
  options: RemoveCurrentUserFromEntityByIdOptions,
): ResponsePromise<unknown, RemoveCurrentUserFromEntityByIdOptions> =>
  request({
    module: 'farmoo',
    method: 'DELETE',
    uri: '/entities/{entityId}',
    path: { entityId: options.entityId },
    options,
  });
